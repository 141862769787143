import fullpage from 'fullpage.js';

if (module.hot) {
  module.hot.accept();
}

new fullpage('#fullpage', {
    //options here
    anchors: [
        'intro',
        'who-are-we',
        'values',
        'mission',
        'objectives',
        'bea',
        'join',
        'apply',
        'thanks',
    ],
    navigation: true,
    navigationPosition: 'right',
    navigationTooltips: ['INTRO', 'WHO ARE WE', 'OUR VALUES', 'MISSION STATEMENT', 'OUR OBJECTIVES', 'SUPPORTED BY THE BEA', 'ENTRY REQUIREMENTS', 'APPLY TO JOIN', 'THANKS'],
});
